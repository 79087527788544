const pdfMap = {
  'VICTORION-2-PREVENT-Sample-1-Year-Anniversary__en_US': require('./VICTORION-2-PREVENT-Sample-1-Year-Anniversary__EN_US.pdf'),
  'VICTORION-2-PREVENT-Sample-Baseline-Visit__EN_US': require('./VICTORION-2-PREVENT-Sample-Baseline-Visit__EN_US.pdf'),
  'VICTORION-2-PREVENT-Sample-Month-3-Visit__EN_US': require('./VICTORION-2-PREVENT-Sample-Month-3-Visit__EN_US.pdf'),
  'VICTORION-2-PREVENT-Sample-Welcome-Message__EN_US': require('./VICTORION-2-PREVENT-Sample-Welcome-Message__EN_US.pdf'),
  // ITL EN_US
  'ITL-2002-Article_01_v1.0__EN_US': require('./ITL-2002-Article_01_v1.0__EN_US.pdf'),
  'ITL-2002-Article_02_v1.0__EN_US': require('./ITL-2002-Article_02_v1.0__EN_US.pdf'),
  'ITL-2002-Article_03_v1.0__EN_US': require('./ITL-2002-Article_03_v1.0__EN_US.pdf'),
  'ITL-2002-Article_04_v1.0__EN_US': require('./ITL-2002-Article_04_v1.0__EN_US.pdf'),
  'ITL-2002-Article_05_v1.0__EN_US': require('./ITL-2002-Article_05_v1.0__EN_US.pdf'),
  'ITL-2002-Article_06_v1.0__EN_US': require('./ITL-2002-Article_06_v1.0__EN_US.pdf'),
  'ITL-2002-Article_07_v1.0__EN_US': require('./ITL-2002-Article_07_v1.0__EN_US.pdf'),
  'ITL-2002-Article_08_v1.0__EN_US': require('./ITL-2002-Article_08_v1.0__EN_US.pdf'),
  'ITL-2002-Article_09_v1.0__EN_US': require('./ITL-2002-Article_09_v1.0__EN_US.pdf'),
  'ITL-2002-Article_10_v1.0__EN_US': require('./ITL-2002-Article_10_v1.0__EN_US.pdf'),
  'ITL-2002-Article_11_v1.0__EN_US': require('./ITL-2002-Article_11_v1.0__EN_US.pdf'),
  'ITL-2002-Article_12_v1.0__EN_US': require('./ITL-2002-Article_12_v1.0__EN_US.pdf'),
  'ITL-2002-Article_13_v1.0__EN_US': require('./ITL-2002-Article_13_v1.0__EN_US.pdf'),
  'ITL-2002-Article_14_v1.0__EN_US': require('./ITL-2002-Article_14_v1.0__EN_US.pdf'),
  'ITL-2002-Article_15_v1.0__EN_US': require('./ITL-2002-Article_15_v1.0__EN_US.pdf'),
  'ITL-2002-Article_16_v1.0__EN_US': require('./ITL-2002-Article_16_v1.0__EN_US.pdf'),
  'ITL-2002-Article_17_v1.0__EN_US': require('./ITL-2002-Article_17_v1.0__EN_US.pdf'),
  'ITL-2002-Article_18_v1.0__EN_US': require('./ITL-2002-Article_18_v1.0__EN_US.pdf'),
  'ITL-2002-Article_19_v1.0__EN_US': require('./ITL-2002-Article_19_v1.0__EN_US.pdf'),
  'ITL-2002-Article_20_v1.0__EN_US': require('./ITL-2002-Article_20_v1.0__EN_US.pdf'),
  'ITL-2002-Article_21_v1.0__EN_US': require('./ITL-2002-Article_21_v1.0__EN_US.pdf'),
  'ITL-2002-Article_22_v1.0__EN_US': require('./ITL-2002-Article_22_v1.0__EN_US.pdf'),
  'ITL-2002-Article_23_v1.0__EN_US': require('./ITL-2002-Article_23_v1.0__EN_US.pdf'),
  'ITL-2002-Article_24_v1.0__EN_US': require('./ITL-2002-Article_24_v1.0__EN_US.pdf'),
  'ITL-2002-Article_25_v1.0__EN_US': require('./ITL-2002-Article_25_v1.0__EN_US.pdf'),
  'ITL-2002-Article_26_v1.0__EN_US': require('./ITL-2002-Article_26_v1.0__EN_US.pdf'),
  'ITL-2002-Article_27_v1.0__EN_US': require('./ITL-2002-Article_27_v1.0__EN_US.pdf'),
  'ITL-2002-Article_28_v1.0__EN_US': require('./ITL-2002-Article_28_v1.0__EN_US.pdf'),
  // ITL EN_AU
  'ITL-2002-Article_01_v1.0__EN_AU': require('./ITL-2002-Article_01_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_02_v1.0__EN_AU': require('./ITL-2002-Article_02_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_03_v1.0__EN_AU': require('./ITL-2002-Article_03_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_04_v1.0__EN_AU': require('./ITL-2002-Article_04_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_05_v1.0__EN_AU': require('./ITL-2002-Article_05_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_06_v1.0__EN_AU': require('./ITL-2002-Article_06_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_07_v1.0__EN_AU': require('./ITL-2002-Article_07_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_08_v1.0__EN_AU': require('./ITL-2002-Article_08_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_09_v1.0__EN_AU': require('./ITL-2002-Article_09_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_10_v1.0__EN_AU': require('./ITL-2002-Article_10_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_11_v1.0__EN_AU': require('./ITL-2002-Article_11_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_12_v1.0__EN_AU': require('./ITL-2002-Article_12_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_13_v1.0__EN_AU': require('./ITL-2002-Article_13_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_14_v1.0__EN_AU': require('./ITL-2002-Article_14_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_15_v1.0__EN_AU': require('./ITL-2002-Article_15_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_16_v1.0__EN_AU': require('./ITL-2002-Article_16_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_17_v1.0__EN_AU': require('./ITL-2002-Article_17_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_18_v1.0__EN_AU': require('./ITL-2002-Article_18_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_19_v1.0__EN_AU': require('./ITL-2002-Article_19_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_20_v1.0__EN_AU': require('./ITL-2002-Article_20_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_21_v1.0__EN_AU': require('./ITL-2002-Article_21_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_22_v1.0__EN_AU': require('./ITL-2002-Article_22_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_23_v1.0__EN_AU': require('./ITL-2002-Article_23_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_24_v1.0__EN_AU': require('./ITL-2002-Article_24_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_25_v1.0__EN_AU': require('./ITL-2002-Article_25_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_26_v1.0__EN_AU': require('./ITL-2002-Article_26_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_27_v1.0__EN_AU': require('./ITL-2002-Article_27_v1.0__EN_AU.pdf'),
  'ITL-2002-Article_28_v1.0__EN_AU': require('./ITL-2002-Article_28_v1.0__EN_AU.pdf'),
  // ITL EN_GB
  'ITL-2002-Article_01_v1.0__EN_GB': require('./ITL-2002-Article_01_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_02_v1.0__EN_GB': require('./ITL-2002-Article_02_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_03_v1.0__EN_GB': require('./ITL-2002-Article_03_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_04_v1.0__EN_GB': require('./ITL-2002-Article_04_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_05_v1.0__EN_GB': require('./ITL-2002-Article_05_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_06_v1.0__EN_GB': require('./ITL-2002-Article_06_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_07_v1.0__EN_GB': require('./ITL-2002-Article_07_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_08_v1.0__EN_GB': require('./ITL-2002-Article_08_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_09_v1.0__EN_GB': require('./ITL-2002-Article_09_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_10_v1.0__EN_GB': require('./ITL-2002-Article_10_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_11_v1.0__EN_GB': require('./ITL-2002-Article_11_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_12_v1.0__EN_GB': require('./ITL-2002-Article_12_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_13_v1.0__EN_GB': require('./ITL-2002-Article_13_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_14_v1.0__EN_GB': require('./ITL-2002-Article_14_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_15_v1.0__EN_GB': require('./ITL-2002-Article_15_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_16_v1.0__EN_GB': require('./ITL-2002-Article_16_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_17_v1.0__EN_GB': require('./ITL-2002-Article_17_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_18_v1.0__EN_GB': require('./ITL-2002-Article_18_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_19_v1.0__EN_GB': require('./ITL-2002-Article_19_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_20_v1.0__EN_GB': require('./ITL-2002-Article_20_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_21_v1.0__EN_GB': require('./ITL-2002-Article_21_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_22_v1.0__EN_GB': require('./ITL-2002-Article_22_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_23_v1.0__EN_GB': require('./ITL-2002-Article_23_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_24_v1.0__EN_GB': require('./ITL-2002-Article_24_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_25_v1.0__EN_GB': require('./ITL-2002-Article_25_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_26_v1.0__EN_GB': require('./ITL-2002-Article_26_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_27_v1.0__EN_GB': require('./ITL-2002-Article_27_v1.0__EN_GB.pdf'),
  'ITL-2002-Article_28_v1.0__EN_GB': require('./ITL-2002-Article_28_v1.0__EN_GB.pdf'),
  // ITL EN_NZ
  'ITL-2002-Article_01_v1.0__EN_NZ': require('./ITL-2002-Article_01_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_02_v1.0__EN_NZ': require('./ITL-2002-Article_02_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_03_v1.0__EN_NZ': require('./ITL-2002-Article_03_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_04_v1.0__EN_NZ': require('./ITL-2002-Article_04_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_05_v1.0__EN_NZ': require('./ITL-2002-Article_05_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_06_v1.0__EN_NZ': require('./ITL-2002-Article_06_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_07_v1.0__EN_NZ': require('./ITL-2002-Article_07_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_08_v1.0__EN_NZ': require('./ITL-2002-Article_08_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_09_v1.0__EN_NZ': require('./ITL-2002-Article_09_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_10_v1.0__EN_NZ': require('./ITL-2002-Article_10_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_11_v1.0__EN_NZ': require('./ITL-2002-Article_11_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_12_v1.0__EN_NZ': require('./ITL-2002-Article_12_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_13_v1.0__EN_NZ': require('./ITL-2002-Article_13_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_14_v1.0__EN_NZ': require('./ITL-2002-Article_14_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_15_v1.0__EN_NZ': require('./ITL-2002-Article_15_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_16_v1.0__EN_NZ': require('./ITL-2002-Article_16_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_17_v1.0__EN_NZ': require('./ITL-2002-Article_17_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_18_v1.0__EN_NZ': require('./ITL-2002-Article_18_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_19_v1.0__EN_NZ': require('./ITL-2002-Article_19_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_20_v1.0__EN_NZ': require('./ITL-2002-Article_20_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_21_v1.0__EN_NZ': require('./ITL-2002-Article_21_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_22_v1.0__EN_NZ': require('./ITL-2002-Article_22_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_23_v1.0__EN_NZ': require('./ITL-2002-Article_23_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_24_v1.0__EN_NZ': require('./ITL-2002-Article_24_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_25_v1.0__EN_NZ': require('./ITL-2002-Article_25_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_26_v1.0__EN_NZ': require('./ITL-2002-Article_26_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_27_v1.0__EN_NZ': require('./ITL-2002-Article_27_v1.0__EN_NZ.pdf'),
  'ITL-2002-Article_28_v1.0__EN_NZ': require('./ITL-2002-Article_28_v1.0__EN_NZ.pdf'),
  // ITL DE_DE
  'ITL-2002-Article_01_v1.0__DE_DE': require('./ITL-2002-Article_01_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_02_v1.0__DE_DE': require('./ITL-2002-Article_02_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_03_v1.0__DE_DE': require('./ITL-2002-Article_03_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_04_v1.0__DE_DE': require('./ITL-2002-Article_04_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_05_v1.0__DE_DE': require('./ITL-2002-Article_05_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_06_v1.0__DE_DE': require('./ITL-2002-Article_06_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_07_v1.0__DE_DE': require('./ITL-2002-Article_07_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_08_v1.0__DE_DE': require('./ITL-2002-Article_08_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_09_v1.0__DE_DE': require('./ITL-2002-Article_09_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_10_v1.0__DE_DE': require('./ITL-2002-Article_10_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_11_v1.0__DE_DE': require('./ITL-2002-Article_11_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_12_v1.0__DE_DE': require('./ITL-2002-Article_12_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_13_v1.0__DE_DE': require('./ITL-2002-Article_13_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_14_v1.0__DE_DE': require('./ITL-2002-Article_14_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_15_v1.0__DE_DE': require('./ITL-2002-Article_15_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_16_v1.0__DE_DE': require('./ITL-2002-Article_16_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_17_v1.0__DE_DE': require('./ITL-2002-Article_17_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_18_v1.0__DE_DE': require('./ITL-2002-Article_18_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_19_v1.0__DE_DE': require('./ITL-2002-Article_19_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_20_v1.0__DE_DE': require('./ITL-2002-Article_20_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_21_v1.0__DE_DE': require('./ITL-2002-Article_21_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_22_v1.0__DE_DE': require('./ITL-2002-Article_22_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_23_v1.0__DE_DE': require('./ITL-2002-Article_23_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_24_v1.0__DE_DE': require('./ITL-2002-Article_24_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_25_v1.0__DE_DE': require('./ITL-2002-Article_25_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_26_v1.0__DE_DE': require('./ITL-2002-Article_26_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_27_v1.0__DE_DE': require('./ITL-2002-Article_27_v1.0__DE_DE.pdf'),
  'ITL-2002-Article_28_v1.0__DE_DE': require('./ITL-2002-Article_28_v1.0__DE_DE.pdf'),
  // ITL ES_ES
  'ITL-2002-Article_01_v1.0__ES_ES': require('./ITL-2002-Article_01_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_02_v1.0__ES_ES': require('./ITL-2002-Article_02_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_03_v1.0__ES_ES': require('./ITL-2002-Article_03_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_04_v1.0__ES_ES': require('./ITL-2002-Article_04_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_05_v1.0__ES_ES': require('./ITL-2002-Article_05_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_06_v1.0__ES_ES': require('./ITL-2002-Article_06_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_07_v1.0__ES_ES': require('./ITL-2002-Article_07_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_08_v1.0__ES_ES': require('./ITL-2002-Article_08_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_09_v1.0__ES_ES': require('./ITL-2002-Article_09_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_10_v1.0__ES_ES': require('./ITL-2002-Article_10_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_11_v1.0__ES_ES': require('./ITL-2002-Article_11_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_12_v1.0__ES_ES': require('./ITL-2002-Article_12_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_13_v1.0__ES_ES': require('./ITL-2002-Article_13_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_14_v1.0__ES_ES': require('./ITL-2002-Article_14_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_15_v1.0__ES_ES': require('./ITL-2002-Article_15_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_16_v1.0__ES_ES': require('./ITL-2002-Article_16_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_17_v1.0__ES_ES': require('./ITL-2002-Article_17_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_18_v1.0__ES_ES': require('./ITL-2002-Article_18_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_19_v1.0__ES_ES': require('./ITL-2002-Article_19_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_20_v1.0__ES_ES': require('./ITL-2002-Article_20_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_21_v1.0__ES_ES': require('./ITL-2002-Article_21_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_22_v1.0__ES_ES': require('./ITL-2002-Article_22_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_23_v1.0__ES_ES': require('./ITL-2002-Article_23_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_24_v1.0__ES_ES': require('./ITL-2002-Article_24_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_25_v1.0__ES_ES': require('./ITL-2002-Article_25_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_26_v1.0__ES_ES': require('./ITL-2002-Article_26_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_27_v1.0__ES_ES': require('./ITL-2002-Article_27_v1.0__ES_ES.pdf'),
  'ITL-2002-Article_28_v1.0__ES_ES': require('./ITL-2002-Article_28_v1.0__ES_ES.pdf'),
  // ES_US
  'ITL-2002-Article_01_v1.0__ES_US': require('./ITL-2002-Article_01_v1.0__ES_US.pdf'),
  'ITL-2002-Article_02_v1.0__ES_US': require('./ITL-2002-Article_02_v1.0__ES_US.pdf'),
  'ITL-2002-Article_03_v1.0__ES_US': require('./ITL-2002-Article_03_v1.0__ES_US.pdf'),
  'ITL-2002-Article_04_v1.0__ES_US': require('./ITL-2002-Article_04_v1.0__ES_US.pdf'),
  'ITL-2002-Article_05_v1.0__ES_US': require('./ITL-2002-Article_05_v1.0__ES_US.pdf'),
  'ITL-2002-Article_06_v1.0__ES_US': require('./ITL-2002-Article_06_v1.0__ES_US.pdf'),
  'ITL-2002-Article_07_v1.0__ES_US': require('./ITL-2002-Article_07_v1.0__ES_US.pdf'),
  'ITL-2002-Article_08_v1.0__ES_US': require('./ITL-2002-Article_08_v1.0__ES_US.pdf'),
  'ITL-2002-Article_09_v1.0__ES_US': require('./ITL-2002-Article_09_v1.0__ES_US.pdf'),
  'ITL-2002-Article_10_v1.0__ES_US': require('./ITL-2002-Article_10_v1.0__ES_US.pdf'),
  'ITL-2002-Article_11_v1.0__ES_US': require('./ITL-2002-Article_11_v1.0__ES_US.pdf'),
  'ITL-2002-Article_12_v1.0__ES_US': require('./ITL-2002-Article_12_v1.0__ES_US.pdf'),
  'ITL-2002-Article_13_v1.0__ES_US': require('./ITL-2002-Article_13_v1.0__ES_US.pdf'),
  'ITL-2002-Article_14_v1.0__ES_US': require('./ITL-2002-Article_14_v1.0__ES_US.pdf'),
  'ITL-2002-Article_15_v1.0__ES_US': require('./ITL-2002-Article_15_v1.0__ES_US.pdf'),
  'ITL-2002-Article_16_v1.0__ES_US': require('./ITL-2002-Article_16_v1.0__ES_US.pdf'),
  'ITL-2002-Article_17_v1.0__ES_US': require('./ITL-2002-Article_17_v1.0__ES_US.pdf'),
  'ITL-2002-Article_18_v1.0__ES_US': require('./ITL-2002-Article_18_v1.0__ES_US.pdf'),
  'ITL-2002-Article_19_v1.0__ES_US': require('./ITL-2002-Article_19_v1.0__ES_US.pdf'),
  'ITL-2002-Article_20_v1.0__ES_US': require('./ITL-2002-Article_20_v1.0__ES_US.pdf'),
  'ITL-2002-Article_21_v1.0__ES_US': require('./ITL-2002-Article_21_v1.0__ES_US.pdf'),
  'ITL-2002-Article_22_v1.0__ES_US': require('./ITL-2002-Article_22_v1.0__ES_US.pdf'),
  'ITL-2002-Article_23_v1.0__ES_US': require('./ITL-2002-Article_23_v1.0__ES_US.pdf'),
  'ITL-2002-Article_24_v1.0__ES_US': require('./ITL-2002-Article_24_v1.0__ES_US.pdf'),
  'ITL-2002-Article_25_v1.0__ES_US': require('./ITL-2002-Article_25_v1.0__ES_US.pdf'),
  'ITL-2002-Article_26_v1.0__ES_US': require('./ITL-2002-Article_26_v1.0__ES_US.pdf'),
  'ITL-2002-Article_27_v1.0__ES_US': require('./ITL-2002-Article_27_v1.0__ES_US.pdf'),
  'ITL-2002-Article_28_v1.0__ES_US': require('./ITL-2002-Article_28_v1.0__ES_US.pdf'),
  // ITL FR_FR
  'ITL-2002-Article_01_v1.0__FR_FR': require('./ITL-2002-Article_01_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_02_v1.0__FR_FR': require('./ITL-2002-Article_02_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_03_v1.0__FR_FR': require('./ITL-2002-Article_03_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_04_v1.0__FR_FR': require('./ITL-2002-Article_04_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_05_v1.0__FR_FR': require('./ITL-2002-Article_05_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_06_v1.0__FR_FR': require('./ITL-2002-Article_06_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_07_v1.0__FR_FR': require('./ITL-2002-Article_07_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_08_v1.0__FR_FR': require('./ITL-2002-Article_08_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_09_v1.0__FR_FR': require('./ITL-2002-Article_09_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_10_v1.0__FR_FR': require('./ITL-2002-Article_10_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_11_v1.0__FR_FR': require('./ITL-2002-Article_11_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_12_v1.0__FR_FR': require('./ITL-2002-Article_12_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_13_v1.0__FR_FR': require('./ITL-2002-Article_13_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_14_v1.0__FR_FR': require('./ITL-2002-Article_14_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_15_v1.0__FR_FR': require('./ITL-2002-Article_15_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_16_v1.0__FR_FR': require('./ITL-2002-Article_16_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_17_v1.0__FR_FR': require('./ITL-2002-Article_17_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_18_v1.0__FR_FR': require('./ITL-2002-Article_18_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_19_v1.0__FR_FR': require('./ITL-2002-Article_19_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_20_v1.0__FR_FR': require('./ITL-2002-Article_20_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_21_v1.0__FR_FR': require('./ITL-2002-Article_21_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_22_v1.0__FR_FR': require('./ITL-2002-Article_22_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_23_v1.0__FR_FR': require('./ITL-2002-Article_23_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_24_v1.0__FR_FR': require('./ITL-2002-Article_24_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_25_v1.0__FR_FR': require('./ITL-2002-Article_25_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_26_v1.0__FR_FR': require('./ITL-2002-Article_26_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_27_v1.0__FR_FR': require('./ITL-2002-Article_27_v1.0__FR_FR.pdf'),
  'ITL-2002-Article_28_v1.0__FR_FR': require('./ITL-2002-Article_28_v1.0__FR_FR.pdf'),
  // ITL NL_NL
  'ITL-2002-Article_01_v1.0__NL_NL': require('./ITL-2002-Article_01_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_02_v1.0__NL_NL': require('./ITL-2002-Article_02_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_03_v1.0__NL_NL': require('./ITL-2002-Article_03_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_04_v1.0__NL_NL': require('./ITL-2002-Article_04_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_05_v1.0__NL_NL': require('./ITL-2002-Article_05_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_06_v1.0__NL_NL': require('./ITL-2002-Article_06_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_07_v1.0__NL_NL': require('./ITL-2002-Article_07_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_08_v1.0__NL_NL': require('./ITL-2002-Article_08_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_09_v1.0__NL_NL': require('./ITL-2002-Article_09_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_10_v1.0__NL_NL': require('./ITL-2002-Article_10_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_11_v1.0__NL_NL': require('./ITL-2002-Article_11_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_12_v1.0__NL_NL': require('./ITL-2002-Article_12_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_13_v1.0__NL_NL': require('./ITL-2002-Article_13_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_14_v1.0__NL_NL': require('./ITL-2002-Article_14_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_15_v1.0__NL_NL': require('./ITL-2002-Article_15_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_16_v1.0__NL_NL': require('./ITL-2002-Article_16_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_17_v1.0__NL_NL': require('./ITL-2002-Article_17_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_18_v1.0__NL_NL': require('./ITL-2002-Article_18_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_19_v1.0__NL_NL': require('./ITL-2002-Article_19_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_20_v1.0__NL_NL': require('./ITL-2002-Article_20_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_21_v1.0__NL_NL': require('./ITL-2002-Article_21_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_22_v1.0__NL_NL': require('./ITL-2002-Article_22_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_23_v1.0__NL_NL': require('./ITL-2002-Article_23_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_24_v1.0__NL_NL': require('./ITL-2002-Article_24_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_25_v1.0__NL_NL': require('./ITL-2002-Article_25_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_26_v1.0__NL_NL': require('./ITL-2002-Article_26_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_27_v1.0__NL_NL': require('./ITL-2002-Article_27_v1.0__NL_NL.pdf'),
  'ITL-2002-Article_28_v1.0__NL_NL': require('./ITL-2002-Article_28_v1.0__NL_NL.pdf'),
  // ITL FR_CA
  'ITL-2002-Article_01_v1.0__FR_CA': require('./ITL-2002-Article_01_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_02_v1.0__FR_CA': require('./ITL-2002-Article_02_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_03_v1.0__FR_CA': require('./ITL-2002-Article_03_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_04_v1.0__FR_CA': require('./ITL-2002-Article_04_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_05_v1.0__FR_CA': require('./ITL-2002-Article_05_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_06_v1.0__FR_CA': require('./ITL-2002-Article_06_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_07_v1.0__FR_CA': require('./ITL-2002-Article_07_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_08_v1.0__FR_CA': require('./ITL-2002-Article_08_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_09_v1.0__FR_CA': require('./ITL-2002-Article_09_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_10_v1.0__FR_CA': require('./ITL-2002-Article_10_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_11_v1.0__FR_CA': require('./ITL-2002-Article_11_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_12_v1.0__FR_CA': require('./ITL-2002-Article_12_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_13_v1.0__FR_CA': require('./ITL-2002-Article_13_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_14_v1.0__FR_CA': require('./ITL-2002-Article_14_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_15_v1.0__FR_CA': require('./ITL-2002-Article_15_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_16_v1.0__FR_CA': require('./ITL-2002-Article_16_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_17_v1.0__FR_CA': require('./ITL-2002-Article_17_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_18_v1.0__FR_CA': require('./ITL-2002-Article_18_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_19_v1.0__FR_CA': require('./ITL-2002-Article_19_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_20_v1.0__FR_CA': require('./ITL-2002-Article_20_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_21_v1.0__FR_CA': require('./ITL-2002-Article_21_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_22_v1.0__FR_CA': require('./ITL-2002-Article_22_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_23_v1.0__FR_CA': require('./ITL-2002-Article_23_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_24_v1.0__FR_CA': require('./ITL-2002-Article_24_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_25_v1.0__FR_CA': require('./ITL-2002-Article_25_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_26_v1.0__FR_CA': require('./ITL-2002-Article_26_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_27_v1.0__FR_CA': require('./ITL-2002-Article_27_v1.0__FR_CA.pdf'),
  'ITL-2002-Article_28_v1.0__FR_CA': require('./ITL-2002-Article_28_v1.0__FR_CA.pdf'),
  // ASLAN
  'ASLAN_TREK-DX_Welcome_Message_Screening__EN_US': require('./ASLAN_TREK-DX_Welcome_Message_Screening__EN_US.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Screening__ES_US': require('./ASLAN_TREK-DX_Welcome_Message_Screening__ES_US.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Screening__FR_CA': require('./ASLAN_TREK-DX_Welcome_Message_Screening__FR_CA.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Screening__DE_DE': require('./ASLAN_TREK-DX_Welcome_Message_Screening__DE_DE.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Screening__PL_PL': require('./ASLAN_TREK-DX_Welcome_Message_Screening__PL_PL.pdf'),
  'ASLAN_TREK-DX_Announcements_Library__EN_US': require('./ASLAN_TREK-DX_Announcements_Library__EN_US.pdf'),
  'ASLAN_TREK-DX_Congratulations_and_Thank_You__EN_US': require('./ASLAN_TREK-DX_Congratulations_and_Thank_You__EN_US.pdf'),
  'ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__EN_US': require('./ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__EN_US.pdf'),
  'ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__EN_US': require('./ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__EN_US.pdf'),
  'ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__EN_US': require('./ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__EN_US.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__EN_US': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__EN_US.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__EN_US': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__EN_US.pdf'),
  'ASLAN_TREK-DK_Schedule_of_Activities_Treatment_Day_1__EN_US': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__EN_US.pdf'),
  'ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__EN_US': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__EN_US.pdf'),
  'ASLAN_TREK-DX_Study_Information_Treatment_Day_1__EN_US': require('./ASLAN_TREK-DX_Study_Information_Treatment_Day_1__EN_US.pdf'),
  'ASLAN_TREK-DX_Thank_You_Treatment_Day_90__EN_US': require('./ASLAN_TREK-DX_Thank_You_Treatment_Day_90__EN_US.pdf'),
  'ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__EN_US': require('./ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__EN_US.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__EN_US': require('./ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__EN_US.pdf'),
  'ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__EN_US': require('./ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__EN_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__EN_US': require('./ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__EN_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__EN_US': require('./ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__EN_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__EN_US': require('./ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__EN_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__EN_US': require('./ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__EN_US.pdf'),
  // ASLAN FR_CA
  'ASLAN_TREK-DX_Congratulations_and_Thank_You__FR_CA': require('./ASLAN_TREK-DX_Congratulations_and_Thank_You__FR_CA.pdf'),
  'ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__FR_CA': require('./ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__FR_CA.pdf'),
  'ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__FR_CA': require('./ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__FR_CA.pdf'),
  'ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__FR_CA': require('./ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__FR_CA.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__FR_CA': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__FR_CA.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__FR_CA': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__FR_CA.pdf'),
  'ASLAN_TREK-DK_Schedule_of_Activities_Treatment_Day_1__FR_CA': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__FR_CA.pdf'),
  'ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__FR_CA': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__FR_CA.pdf'),
  'ASLAN_TREK-DX_Study_Information_Treatment_Day_1__FR_CA': require('./ASLAN_TREK-DX_Study_Information_Treatment_Day_1__FR_CA.pdf'),
  'ASLAN_TREK-DX_Thank_You_Treatment_Day_90__FR_CA': require('./ASLAN_TREK-DX_Thank_You_Treatment_Day_90__FR_CA.pdf'),
  'ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__FR_CA': require('./ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__FR_CA.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__FR_CA': require('./ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__FR_CA.pdf'),
  'ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__FR_CA': require('./ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__FR_CA.pdf'),
  'ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__FR_CA': require('./ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__FR_CA.pdf'),
  'ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__FR_CA': require('./ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__FR_CA.pdf'),
  'ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__FR_CA': require('./ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__FR_CA.pdf'),
  'ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__FR_CA': require('./ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__FR_CA.pdf'),
  // ASLAN ES_US
  'ASLAN_TREK-DX_Congratulations_and_Thank_You__ES_US': require('./ASLAN_TREK-DX_Congratulations_and_Thank_You__ES_US.pdf'),
  'ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__ES_US': require('./ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__ES_US.pdf'),
  'ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__ES_US': require('./ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__ES_US.pdf'),
  'ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__ES_US': require('./ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__ES_US.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__ES_US': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__ES_US.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__ES_US': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__ES_US.pdf'),
  'ASLAN_TREK-DK_Schedule_of_Activities_Treatment_Day_1__ES_US': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__ES_US.pdf'),
  'ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__ES_US': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__ES_US.pdf'),
  'ASLAN_TREK-DX_Study_Information_Treatment_Day_1__ES_US': require('./ASLAN_TREK-DX_Study_Information_Treatment_Day_1__ES_US.pdf'),
  'ASLAN_TREK-DX_Thank_You_Treatment_Day_90__ES_US': require('./ASLAN_TREK-DX_Thank_You_Treatment_Day_90__ES_US.pdf'),
  'ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__ES_US': require('./ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__ES_US.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__ES_US': require('./ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__ES_US.pdf'),
  'ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__ES_US': require('./ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__ES_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__ES_US': require('./ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__ES_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__ES_US': require('./ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__ES_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__ES_US': require('./ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__ES_US.pdf'),
  'ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__ES_US': require('./ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__ES_US.pdf'),
  // ASLAN DE_DE
  'ASLAN_TREK-DX_Congratulations_and_Thank_You__DE_DE': require('./ASLAN_TREK-DX_Congratulations_and_Thank_You__DE_DE.pdf'),
  'ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__DE_DE': require('./ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__DE_DE.pdf'),
  'ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__DE_DE': require('./ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__DE_DE.pdf'),
  'ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__DE_DE': require('./ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__DE_DE.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__DE_DE': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__DE_DE.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__DE_DE': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__DE_DE.pdf'),
  'ASLAN_TREK-DK_Schedule_of_Activities_Treatment_Day_1__DE_DE': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__DE_DE.pdf'),
  'ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__DE_DE': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__DE_DE.pdf'),
  'ASLAN_TREK-DX_Study_Information_Treatment_Day_1__DE_DE': require('./ASLAN_TREK-DX_Study_Information_Treatment_Day_1__DE_DE.pdf'),
  'ASLAN_TREK-DX_Thank_You_Treatment_Day_90__DE_DE': require('./ASLAN_TREK-DX_Thank_You_Treatment_Day_90__DE_DE.pdf'),
  'ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__DE_DE': require('./ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__DE_DE.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__DE_DE': require('./ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__DE_DE.pdf'),
  'ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__DE_DE': require('./ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__DE_DE.pdf'),
  'ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__DE_DE': require('./ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__DE_DE.pdf'),
  'ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__DE_DE': require('./ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__DE_DE.pdf'),
  'ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__DE_DE': require('./ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__DE_DE.pdf'),
  'ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__DE_DE': require('./ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__DE_DE.pdf'),
  // ASLAN PL_PL
  'ASLAN_TREK-DX_Congratulations_and_Thank_You__PL_PL': require('./ASLAN_TREK-DX_Congratulations_and_Thank_You__PL_PL.pdf'),
  'ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__PL_PL': require('./ASLAN_TREK-DX_Early_Discontinuation_Visit_Information__PL_PL.pdf'),
  'ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__PL_PL': require('./ASLAN_TREK-DX_Learn_More_About_Eczema_Treatment_Day_15__PL_PL.pdf'),
  'ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__PL_PL': require('./ASLAN_TREK-DX_Reminder_End_of_Study_Visit_Treatment_Day_164__PL_PL.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__PL_PL': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_1_Treatment_Day_122__PL_PL.pdf'),
  'ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__PL_PL': require('./ASLAN_TREK-DX_Reminder_Follow-up_Visit_2_Treatment_Day_136__PL_PL.pdf'),
  'ASLAN_TREK-DK_Schedule_of_Activities_Treatment_Day_1__PL_PL': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__PL_PL.pdf'),
  'ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__PL_PL': require('./ASLAN_TREK-DX_Schedule_of_Activities_Treatment_Day_1__PL_PL.pdf'),
  'ASLAN_TREK-DX_Study_Information_Treatment_Day_1__PL_PL': require('./ASLAN_TREK-DX_Study_Information_Treatment_Day_1__PL_PL.pdf'),
  'ASLAN_TREK-DX_Thank_You_Treatment_Day_90__PL_PL': require('./ASLAN_TREK-DX_Thank_You_Treatment_Day_90__PL_PL.pdf'),
  'ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__PL_PL': require('./ASLAN_TREK-DX_Tips_for_Managing_Eczema_Treatment_Day_48__PL_PL.pdf'),
  'ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__PL_PL': require('./ASLAN_TREK-DX_Welcome_Message_Treatment_Day_1__PL_PL.pdf'),
  'ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__PL_PL': require('./ASLAN_TREK-DX_What_Is_Eblasakimab_Treatment_Day_36__PL_PL.pdf'),
  'ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__PL_PL': require('./ASLAN_TREK-DX_Your_Week_4_Visit_Is_Coming_Up_Treatment_Day_24__PL_PL.pdf'),
  'ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__PL_PL': require('./ASLAN_TREK-DX_Your_Week_8_Visit_Is_Coming_Up_Treatment_Day_52__PL_PL.pdf'),
  'ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__PL_PL': require('./ASLAN_TREK-DX_Your_Week_12_Visit_Is_Coming_Up_Treatment_Day_80__PL_PL.pdf'),
  'ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__PL_PL': require('./ASLAN_TREK-DX_Your_Week_16_Visit_Is_Coming_Up_Treatment_Day_108__PL_PL.pdf'),
  // NOVARTIS EN_US
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Study_Discontinuation_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Early_Study_Discontinuation_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__EN_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__EN_US': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__EN_US.pdf'),
  // NOVARTIS ES_US
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_US.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_US': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_US.pdf'),
  // NOVARTIS ES_AR
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_AR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_AR': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_AR.pdf'),
  // NOVARTIS ES_ES
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__ES_ES.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_ES': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__ES_ES.pdf'),
  // NOVARTIS BG_BG
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__BG_BG.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__BG_BG': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__BG_BG.pdf'),
  // Novartis FR_CA
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__FR_CA.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__FR_CA': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__FR_CA.pdf'),
  // NOVARTIS FR_FR
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__FR_FR.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__FR_FR': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__FR_FR.pdf'),
  // NOVARTIS NL_NL
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__NL_NL.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__NL_NL': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__NL_NL.pdf'),
  // NOVARTIS LT_LT
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__LT_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__LT_LT': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__LT_LT.pdf'),
  // NOVARTIS SK_SK
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__SK_SK.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__SK_SK': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__SK_SK.pdf'),
  // NOVARTIS RU_LT
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__RU_LT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__RU_LT': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__RU_LT.pdf'),
  // NOVARTIS PT_PT
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__PT_PT.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__PT_PT': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__PT_PT.pdf'),
  // NOVARTIS HU_HU
  'Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_1-Year_Anniversary_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_2-Year_Anniversary_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_3-Year_Anniversary_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_4-Year_Anniversary_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_5-Year_Anniversary_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(1)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(2)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(3)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(4)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(5)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(6)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(7)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(8)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(9)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(10)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(11)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(12)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(13)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(14)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(15)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(16)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(17)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(18)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(19)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(20)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(21)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(22)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(23)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(24)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(25)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(26)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(27)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(28)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(29)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(30)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(31)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(32)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(33)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(34)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(35)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(36)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(37)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(38)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(39)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(40)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(41)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(42)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(43)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(44)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Article(45)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Baseline_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Early_Treatment_Discontinuation_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_End_of_Study_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Follow-up_Phone_Call_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-3_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-9_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-15_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-21_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-27_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-33_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-39_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-45_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-51_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-57_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-63_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Month-69_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_One_Month_to_Your_Next_Visit_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Thank_You(1)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Thank_You(2)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Thank_You(3)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Thank_You(4)_v1.0__HU_HU.pdf'),
  'Novartis_VICTORION-2-PREVENT_Welcome_v1.0__HU_HU': require('./Novartis_VICTORION-2-PREVENT_Welcome_v1.0__HU_HU.pdf'),
  // D3 Health Patient Guide
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__BG_BG: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__BG_BG.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__EN_US: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__EN_US.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_AR: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_AR.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_ES: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_ES.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_US: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__ES_US.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__FR_CA: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__FR_CA.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__FR_FR: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__FR_FR.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__HU_HU: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__HU_HU.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__LT_LT: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__LT_LT.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__NL_NL: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__NL_NL.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__PT_PT: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__PT_PT.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__RU_LT: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__RU_LT.pdf'),
  Datacubed_Health_Patient_Guide_Novartis_V2P_v1__SK_SK: require('./Datacubed_Health_Patient_Guide_Novartis_V2P_v1__SK_SK.pdf'),
  // Misc
  'Erase_PTSD_Now_ROBRA_DNA_Collection_Kit_Instructions_v1.0__EN_US': require('./Erase_PTSD_Now_ROBRA_DNA_Collection_Kit_Instructions_v1.0__EN_US.pdf'),
  // Alnylam
  'Alnylam_ALN-TTRSC04-NT-001_Care_Partner_Survey_v1.0__EN_US': require('./Alnylam_ALN-TTRSC04-NT-001_Care_Partner_Survey_v1.0__EN_US.pdf'),
  'Alnylam_ALN-TTRSC04-NT-001_Participant_Survey_v1.0__EN_US': require('./Alnylam_ALN-TTRSC04-NT-001_Participant_Survey_v1.0__EN_US.pdf'),
  // CymaBay
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_US.pdf'),
  // CymaBay ES_US
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_US.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_US': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_US.pdf'),
  // CymaBay ES_AR
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_AR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_AR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_AR.pdf'),
  // CymaBay ES_CL
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_CL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_CL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_CL.pdf'),
  // CymaBay ES_MX
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__ES_MX.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_MX': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__ES_MX.pdf'),
  // CymaBay AR_IL
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__AR_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__AR_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__AR_IL.pdf'),
  // CymaBay EN_AU
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_AU.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_AU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_AU.pdf'),
  // CymaBay EN_CA
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_CA.pdf'),
  // CymaBay EN_NZ
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__EN_NZ.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_NZ': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__EN_NZ.pdf'),
  // CymaBay FR_CA
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__FR_CA.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__FR_CA': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__FR_CA.pdf'),
  // CymaBay HE_IL
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__HE_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__HE_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__HE_IL.pdf'),
  // CymaBay KO_KR
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__KO_KR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__KO_KR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__KO_KR.pdf'),
  // CymaBay RU_IL
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__RU_IL': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__RU_IL.pdf'),
  // CymaBay RU_RU
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__RU_IL.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__RU_RU': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__RU_IL.pdf'),
  // CymaBay TR_TR
  'CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_01_WELCOME-TO-THE-SCREENING-PERIOD_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_02_UDCA-INFORMATION_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_03_WELCOME-TO-THE-RANDOMIZATION_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_04_VISIT-SCHEDULE-SNAPSHOT_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_05_PBC-OVERVIEW_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_06_LEARN-MORE-ABOUT-THE-STUDY-DRUG-SELADELPAR_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_07_REMINDER_VISIT-3-(MONTH-1)_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_08_RECIPE-SHUFFLE_SALMON-AND-VEGGIES-WITH-QUINOA_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_09_MORNING-STRETCHES_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_10_TIPS-FOR-MENTAL-WELLNESS_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_11_PATIENT-STORY_KATIE_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_12_GET-BETTER-SLEEP_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_13_MINDFULNESS-GAMES_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_14_REMINDER_VISIT-4-(MONTH-3)_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_15_THANK-YOU_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_16_ART-THERAPY_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_17_ESSENTIAL-INGREDIENTS-FOR-THE-PANTRY_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_18_BUILDING-A-STRONG-SUPPORT-SYSTEM_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_19_RECIPE-SHUFFLE_BLUEBERRY-SMOOTHIE_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_20_PATIENT-STORY_IVETTE_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_21_REMINDER_VISIT-5-(MONTH-6)_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_22_MEDITATION-TIPS_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_23_PATIENT-STORY_JENNIFER_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_24_REMINDER_VISIT-6-(MONTH-9)_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_25_GARDENING_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_26_RECIPE-SHUFFLE_GREEN-TEA-SMOOTHIE_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_27_REMINDER_END-OF-TREATMENT-VISIT-(MONTH-12)_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_28_REMINDER_FOLLOW-UP-VISIT_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_29_CONGRATULATIONS_v1.0__TR_TR.pdf'),
  'CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__TR_TR': require('./CymaBay_CB8025-32251(IDEAL)_Engagement_30_REMINDER_EARLY-TERMINATION-VISIT-INFORMATION_v1.0__TR_TR.pdf'),
  // Regeneron
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_01_V1.0__EN_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_01_V1.0__EN_US.pdf'),
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_02_V1.0__EN_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_02_V1.0__EN_US.pdf'),
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_03_V1.0__EN_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_03_V1.0__EN_US.pdf'),
  // Regeneron ES_US
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_01_V1.0__ES_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_01_V1.0__ES_US.pdf'),
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_02_V1.0__ES_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_02_V1.0__ES_US.pdf'),
  'Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_03_V1.0__ES_US': require('./Regeneron_R0000-CV-CES-2216_Engagement_Content_Design_File_03_V1.0__ES_US.pdf'),
  // Demo Content Sample EN_US
  'Content-Sample-01_v1.0__EN_US': require('./Content-Sample-01_v1.0__EN_US.pdf'),
  'Content-Sample-02_v1.0__EN_US': require('./Content-Sample-02_v1.0__EN_US.pdf'),
  'Content-Sample-03_v1.0__EN_US': require('./Content-Sample-03_v1.0__EN_US.pdf'),
  'Content-Sample-04_v1.0__EN_US': require('./Content-Sample-04_v1.0__EN_US.pdf'),
  'Content-Sample-05_v1.0__EN_US': require('./Content-Sample-05_v1.0__EN_US.pdf'),
  'Content-Sample-06_v1.0__EN_US': require('./Content-Sample-06_v1.0__EN_US.pdf'),
  'Content-Sample-07_v1.0__EN_US': require('./Content-Sample-07_v1.0__EN_US.pdf'),
  'Content-Sample-08_v1.0__EN_US': require('./Content-Sample-08_v1.0__EN_US.pdf'),
  // Demo Content Astra Zeneca with Empath Labs
  'AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Breathing__EN_US': require('./AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Breathing__EN_US.pdf'),
  'AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Entertainment__EN_US': require('./AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Entertainment__EN_US.pdf'),
  'AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Communication__EN_US': require('./AZ_Empath_My_Special_Aflac_Duck_Facilitation_Cards_Communication__EN_US.pdf'),
}

export default pdfMap
